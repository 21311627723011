import React from 'react';
import { User, Bot, X } from 'lucide-react';
import './CallTranscript.css';

const CallTranscript = ({ queueDebugInfo, currentPlayingSeq, pausedPlayingSeq, isVisible, debug, toggleVisibility }) => {

  return (
    <div className={`transcript-content ${isVisible ? 'visible' : ''}`}>
      <div className="transcript-header" onClick={() => toggleVisibility('transcript')}>
        <span>Call Transcript</span>
        <div title="Close transcript" style={{ display: 'flex', alignItems: 'center' }}>
          <X size={16} />
        </div>
      </div>
      {queueDebugInfo && Object.entries(queueDebugInfo).length > 0 ? (
        Object.entries(queueDebugInfo).map(([txid, session]) => (
          <div key={txid} className="transcript-session">
            <div className="session-separator">
              {debug && <span className="debug-txid">{txid}</span>}
            </div>
            <div className="session-messages">
              {session.query && (
                <div className="message user">
                  <User size={14} />
                  <div className="message-content">{session.query}</div>
                </div>
              )}
              {session.messages && session.messages.map((message, index) => {
                const isPlaying = message.sequenceNumber === currentPlayingSeq?.sequenceNumber;
                const isPaused = message.sequenceNumber === pausedPlayingSeq?.sequenceNumber;
                const statusText = isPlaying ? '[Playing]' : isPaused ? '[Paused]' : '';
                return (
                  <div key={index} className={`message agent ${isPlaying || isPaused ? 'active' : ''} ${index === 0 ? 'first-message' : ''}`}>
                    {index === 0 ? <Bot size={16} /> : null} {/* Ensure this is Bot, not User */}
                    <div className="message-content">
                      {message.text}
                      {statusText && <span className="message-status">{statusText}</span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))
      ) : (
        <div className="no-transcript">No transcript data available</div>
      )}
    </div>
  );
};

export default CallTranscript;