import React from 'react';
import './TalkingWaveAnimation.css';

const TalkingWaveAnimation = ({ type = 'bot', waveData, isPaused, isMuted }) => {
  const waveHeights = waveData.map(data => data.height);

  if (type === 'user' && isMuted) {
    return null; // Don't render anything for user when muted
  }

  return (
    <svg className={`talking-wave ${type}_talking-wave`} viewBox="0 0 150 100" xmlns="http://www.w3.org/2000/svg">
      {[...Array(4)].map((_, index) => {
        const height = waveHeights[index % waveHeights.length];

        return (
          <rect
            key={index}
            x={index * 37.5}
            y={100 - height}
            width={30}
            height={height}
            rx={15}
            ry={15}
            fill={type === 'bot' ? 'blue' : 'green'}
          >
            {!(isPaused && type === 'bot') && (
              <>
                <animate
                  attributeName="height"
                  values={`0; ${height}; 0`}
                  dur={`${Math.random() * 0.4 + 0.3}s`}
                  begin={`${index * 0.1}s`}
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="y"
                  values={`100; ${100 - height}; 100`}
                  dur={`${Math.random() * 0.4 + 0.3}s`}
                  begin={`${index * 0.1}s`}
                  repeatCount="indefinite"
                />
              </>
            )}
          </rect>
        );
      })}
    </svg>
  );
};

export default TalkingWaveAnimation;
