import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { setLogLevel, getLogLevel, showLogLevels } from './utils/logger'; // Import the setLogLevel, getLogLevel, and showLogLevels functions

// Expose the setLogLevel, getLogLevel, and showLogLevels functions to the window object for access in the developer console
window.setLogLevel = setLogLevel;
window.getLogLevel = getLogLevel;
window.showLogLevels = showLogLevels;

// Set initial log level
const savedLogLevel = localStorage.getItem('logLevel') || (process.env.NODE_ENV === 'development' ? 'debug' : 'error');
setLogLevel(savedLogLevel);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="1070391909379-btdd329p49g5ivpp63i9e210b3tvcpll.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
