/**
 * Creates a debounced function that delays invoking `func` until after `wait` milliseconds
 * have elapsed since the last time the debounced function was invoked.
 *
 * @param {Function} func The function to debounce.
 * @param {number} wait The number of milliseconds to delay.
 * @returns {Function} Returns the new debounced function.
 */
export const debounce = (func, wait) => {
    let timeoutId;
  
    const debouncedFunc = function (...args) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), wait);
    };
  
    debouncedFunc.cancel = () => {
      clearTimeout(timeoutId);
    };
  
    return debouncedFunc;
  };