import React from 'react';
import './BotAvatar.css'; // Import your CSS file

const BotAvatar = () => (
    <svg viewBox="0 0 50 50" className="bot-avatar">
      <rect x="10" y="5" width="30" height="40" rx="10" ry="10" fill="#f2994a" /> {/* Head (taller) */}
      <circle cx="25" cy="20" r="8" fill="#2f80ed" /> {/* Bigger circle */}
      <rect x="17" y="18" width="4" height="4" rx="1.5" ry="1.5" fill="white" />  {/* Left eye (bigger) */}
      <rect x="29" y="18" width="4" height="4" rx="1.5" ry="1.5" fill="white" />  {/* Right eye (bigger) */}
      <path d="M17 28 Q25 35 33 28" stroke="black" strokeWidth="2" fill="none" /> {/* Wider smile */}
      <rect x="17" y="35" width="4" height="1" fill="black" /> {/* Details below eyes */}
      <rect x="29" y="35" width="4" height="1" fill="black" />
    </svg>
  );

export default BotAvatar;
