import React, { useEffect, useState } from 'react';
import Header from '../Header/Header'; // Import Header
import Footer from '../Footer/Footer'; // Import Footer
import './Privacy.css'; // Assume you have a CSS file for styling

const Privacy = () => {
  const [content, setContent] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        setUser(JSON.parse(storedUser));
    }
}, []);

  useEffect(() => {
    fetch('/privacy.html')
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error('Privacy policy HTML not found');
        }
      })
      .then(html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const body = doc.body;
        setContent(body.innerHTML);
      })
      .catch(err => {
        console.error('Failed to load privacy policy:', err);
        // Fallback to hardcoded content if fetch fails
        setContent(`
          <div class="privacy-content">
            <h1>Privacy Policy</h1>
            <p>Last updated: ${new Date().toLocaleDateString()}</p>
            <p>At Rapo.ai, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of your data when you use our service.</p>
            <!-- Add more hardcoded content here -->
          </div>
        `);
      });
  }, []);

  const handleSession = (userInfo) => {
    console.log("handleSession", userInfo);
    if (userInfo) {
        setUser(userInfo);
    } else {
        setUser(null); // Clear the user session if null is passed
    }
  };

  return (
    <div className="privacy-page"> {/* Apply the consistent background styling */}
      <Header handleSession={handleSession} user={user} /> {/* Include the Header */}
      <div className="privacy-content" dangerouslySetInnerHTML={{ __html: content }} />
      <Footer /> {/* Include the Footer */}
    </div>
  );
};

export default Privacy;