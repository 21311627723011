import React, { useEffect, useState, useRef } from 'react';
import './LoginButton.css';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { ChevronDown, ChevronUp, LogOut } from 'lucide-react';

const LoginButton = ({ userInfo, handleSession, generateSessionId }) => {
  const [user, setUser] = useState(userInfo); // Initialize with userInfo from parent
  const [initError, setInitError] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    setUser(userInfo); // Update local state when userInfo prop changes
  }, [userInfo]);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    try {
      const decodedToken = jwtDecode(credentialResponse.credential);
      const sessionId = generateSessionId();
      const userDetails = {
        id: decodedToken.sub,
        email: decodedToken.email,
        name: decodedToken.name,
        givenName: decodedToken.given_name,
        familyName: decodedToken.family_name,
        picture: decodedToken.picture,
        sessionKey: sessionId,
      };
      localStorage.setItem('user', JSON.stringify(userDetails));
      setUser(userDetails);
      handleSession(userDetails); // Update session in parent
    } catch (error) {
      console.error('Error processing login:', error);
      setInitError('Failed to process login information');
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google Login Failed:', error);
    setInitError('Google Sign-In failed. Please try again.');
  };

  const handleLogout = async () => {
    try {
      setUser(null);
      localStorage.removeItem('user');
      handleSession(null);

      if (window.google && window.google.accounts) {
        try {
          await new Promise((resolve, reject) => {
            window.google.accounts.id.disableAutoSelect();
            window.google.accounts.id.revoke(user.email, (done) => {
              if (done.error) {
                reject(new Error(done.error));
              } else {
                console.log('User signed out from Google');
                resolve();
              }
            });
          });
        } catch (googleError) {
          console.warn('Error during Google logout:', googleError);
          googleLogout(); // Fallback
        }
      } else {
        googleLogout(); // Fallback
      }
    } catch (error) {
      console.error('Error during logout:', error);
      setInitError('An error occurred during logout, but local session has been cleared.');
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="google-login">
      {user ? (
        <div className="user-info" ref={dropdownRef}>
          <div className="user-profile" onClick={toggleDropdown}>
            <img src={user.picture} alt={user.name} title={user.name} className="user-image" />
            {dropdownOpen ? <ChevronUp className="up-arrow" /> : <ChevronDown className="down-arrow" />}
          </div>
          {dropdownOpen && (
            <div className="dropdown-menu">
              <div className="user-details">
                <span>Hi, {user.name}</span>
                <span>Email: {user.email}</span>
              </div>
              <button className="d-flex-c logout-button" onClick={handleLogout}>
                <LogOut width={'16'} height={'16'} style={{ marginRight: '2px' }} /> Logout
              </button>
            </div>
          )}
        </div>
      ) : (
        <>
          {initError ? (
            <div className="error-message">{initError}</div>
          ) : (
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
              text="Sign in"
              useOneTap
            />
          )}
        </>
      )}
    </div>
  );
};

export default LoginButton;