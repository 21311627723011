import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './ContactUs.css';

const ContactUs = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        setUser(JSON.parse(storedUser));
    }
}, []);
  const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSffRnHup59_vnuu3HmelFN4E397yfZtrW31-Xz2Qxr8ZLt1GQ/viewform?embedded=true';

  const handleSession = (userInfo) => {
    console.log("handleSession", userInfo);
    if (userInfo) {
        setUser(userInfo);
    } else {
        setUser(null); // Clear the user session if null is passed
    }
  };

  return (
    <div className="contact-us-page">
      <Header handleSession={handleSession} user={user} />
      <div className="contact-us-content">
        <iframe
          src={formUrl}
          title="Contact Form"
          style={{ width: '100%', height: '1500px', border: 'none' }} // Set a large height
        >
          Loading…
        </iframe>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;