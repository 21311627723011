import React from 'react';
import './Header.css';
import logo from '../../images/rapo-io.png';
import LoginButton from '../LoginButton/LoginButton';
import { Link } from 'react-router-dom';

const Header = ({ user, handleSession }) => {

  const generateSessionId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // eslint-disable-next-line no-mixed-operators
      const r = Math.random() * 16 | 0;
      // eslint-disable-next-line no-mixed-operators
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  return (
    <>
      <header className="header">
        <div className="header-content">
          <Link to={`/`}>
            <img src={logo} alt="Rapo.ai Logo" className="logo" />
          </Link>
          <div className="header-text">
            <div className="subheader">
              <span className="aaas">Get started with our Kick AaaS (aiAgent-as-a-Service) Platform </span>
            </div>
            <a href="https://platform.rapo.ai" style={{ textDecoration: 'none' }}>
              <h1 className="hover-effect" style={{ color: '#FFC107', display: 'flex', alignItems: 'center' }}>
                Build Your AI Voice Agent Today 
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="16" 
                  height="16"   
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  style={{ marginLeft: '8px', alignSelf: 'center' }} // Ensures the icon is vertically middle-aligned
                >
                  <path d="M4 2l8 6-8 6V2z"/>
                </svg>
              </h1>
            </a>
          </div>
          <LoginButton userInfo={user} handleSession={handleSession} generateSessionId={generateSessionId} />
        </div>
      </header>

      {/* <ul className="feature-list">
        <li>Adapts to your business</li>
        <li>Human-like conversations</li>
        <li>Multilingual</li>
        <li>Engaging</li>
        <li>Efficient</li>
        <li>Improves customer satisfaction</li>
        <li>Available 24/7</li>
      </ul> */}
    </>
  );
};

export default Header;
