import React, { useEffect } from 'react';

const AudioContextTest = () => {
    useEffect(() => {
        async function testAudio() {
            try {
                const testStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const testContext = new (window.AudioContext || window.webkitAudioContext)();
                const testSource = testContext.createMediaStreamSource(testStream);
                console.log('Test source created successfully:', testSource);
            } catch (err) {
                console.error('Test failed:', err);
            }
        }
        testAudio();
    }, []);

    return <div>Check the console for AudioContext test results.</div>;
};

export default AudioContextTest;