import React, { useState, useEffect, useRef, useCallback } from 'react';
import './BannerRotation.css';
import bannerData from './bannerData.json';

const BannerRotation = () => {
  const [banners] = useState(bannerData);
  const [isPaused, setIsPaused] = useState(false);
  const [isWindowFocused, setIsWindowFocused] = useState(true);
  const bannerReelRef = useRef(null);
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  const lastTimeRef = useRef(0);
  const progressRef = useRef(0);

  const ANIMATION_DURATION = 40000; // 40 seconds for full rotation

  const checkVisibility = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
      const totalHeight = rect.height;
      const visiblePercentage = (visibleHeight / totalHeight) * 100;
      return visiblePercentage > 45; // Pause when less than 45% is visible
    }
    return false;
  }, []);

  const animate = useCallback(() => {
    const timestamp = performance.now();
    if (!lastTimeRef.current) lastTimeRef.current = timestamp;
    const deltaTime = timestamp - lastTimeRef.current;

    if (!isPaused && isWindowFocused && checkVisibility()) {
      progressRef.current += deltaTime / ANIMATION_DURATION;
      
      // Reset when we've scrolled through all original banners
      if (progressRef.current >= 1) {
        progressRef.current = 0;
        if (bannerReelRef.current) {
          bannerReelRef.current.style.transition = 'none';
          bannerReelRef.current.style.transform = 'translateX(0)';
          void bannerReelRef.current.offsetHeight; // Trigger reflow
          bannerReelRef.current.style.transition = 'transform 0.1s linear';
        }
      } else if (bannerReelRef.current) {
        bannerReelRef.current.style.transform = `translateX(${-progressRef.current * 100}%)`;
      }
    }

    lastTimeRef.current = timestamp;
    animationRef.current = requestAnimationFrame(animate);
  }, [isPaused, isWindowFocused, checkVisibility]);

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animate);

    const handleFocus = () => setIsWindowFocused(true);
    const handleBlur = () => setIsWindowFocused(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [animate]);

  useEffect(() => {
    if (bannerReelRef.current) {
      bannerReelRef.current.style.transition = 'none';
      bannerReelRef.current.style.transform = `translateX(0%)`;
      void bannerReelRef.current.offsetHeight;
      bannerReelRef.current.style.transition = '';
    }
  }, [banners]);

  const handleMouseEnter = useCallback(() => {
    setIsPaused(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsPaused(false);
  }, []);

  return (
    <div 
      className="banner-container" 
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div ref={bannerReelRef} className="banner-reel">
        {[...banners, ...banners].map((banner, index) => (
          <div key={`${banner.id}-${index}`} className="banner-card">
            <div className="banner-view">
              <img src={require(`../../images/banner/${banner.image}`)} alt={banner.title} />
            </div>
            <div className="banner-info">
              <h2>{banner.title}</h2>
              <p>{banner.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BannerRotation;