import React, { useMemo } from 'react';
import BotAvatar from './BotAvatar';
import TalkingWaveAnimation from './TalkingWaveAnimation';
import { ChevronsRight, Pause, Play } from 'lucide-react';
import AutoInterrupt from './AutoInterrrupt';

const ProgressBar = ({ audioProgress }) => {
  
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${audioProgress}%` }}></div>
    </div>
  );
};

const BotSide = (
  { state, waveData, handleInterrupt, handleBotPause, handleBotPlay, currentBotMessage, audioProgress, isBotAudioPlaying, isListening, setIsCallInterrupt, isCallInterrupt, currentTxid, isPaused }
  ) => {

  const MemoizedTalkingWaveAnimation = useMemo(() => (
    <TalkingWaveAnimation type={"bot"} waveData={waveData} isPaused={isPaused} />
  ), [waveData, isPaused]);

  return  (
  <div className="side-container bot-side">
    <div className="bot-avatar-container">
      <BotAvatar />
      <div className='w-100 status-text txt-uc'>AGENT</div>
    </div>
    
    <div className={`chat-bubble bot-bubble-container ${state}`}>
      {state === 'processing' && (
        <div className="status-text bot-processing">
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      )}
      {(state === 'botSpeaking' || state === 'processing') && (
        <AutoInterrupt
          isCallInterrupt={isCallInterrupt}
          setIsCallInterrupt={setIsCallInterrupt}
        />
      )}
      
      <div className="bot-content-wrapper">
        {isBotAudioPlaying !== 'no' && (
          <>
            <div className="bot-animation-container">
              <div className="bot-talking-wave">{MemoizedTalkingWaveAnimation}</div>
              <div className="interrupt-container d-flex-c">
                <div className='interrupt-div d-flex-c'>
                  <button className="talk-button interrupt-button" onClick={() => handleInterrupt(currentTxid)} title="Interrupt call">
                    <ChevronsRight size={16} />
                  </button>
                  <div className='status-text txt-uc'>SKIP</div>
                </div>
                
                  <div className='hold-continue-div d-flex-c'>
                    { isBotAudioPlaying === 'pause' ? 
                      <button className="talk-button interrupt-button play-button" onClick={() => handleBotPlay(currentTxid)} title="Continue playing audio">
                        <Play size={14} />
                      </button> :
                      <button className="talk-button interrupt-button pause-button" onClick={() => handleBotPause(currentTxid)} title="Pause playing audio">
                        <Pause size={14} />
                      </button>
                    }
                    <div className='status-text txt-uc'>{isBotAudioPlaying === 'pause' ? 'Play' : 'Hold'}</div>
                  </div>
              </div>
              
            </div>
          </>
        )}
        
        {state === 'idle' && isBotAudioPlaying === 'no' && (
          <div className='status-text w-100'>
            I'm ready for a conversation! <br/>
          </div>
        )}
        {(state === 'userSpeaking' || isListening === true) && isBotAudioPlaying === 'no' && (
          <div className='status-text w-100'>Listening to you...</div>
        )}
        { currentBotMessage && isBotAudioPlaying !== 'no' && (
          <div className="bot-message-container">
            <p className="bot-message">{currentBotMessage}</p>
          </div>
        )}
        {isBotAudioPlaying !== 'no' && (
          <ProgressBar audioProgress={audioProgress} />
        )}
      </div>
      <div className="corner-bottom-left"></div>
      
    </div>
  </div>
)};

export default BotSide;