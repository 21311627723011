// src/hooks/useLogRenders.js

import { useRef, useEffect } from 'react';
import log from '../utils/logger';

function useLogRenders(componentName) {
  const renderCount = useRef(0);

  useEffect(() => {
    renderCount.current += 1;
    log.info(`${componentName} rendered. Count: ${renderCount.current}`);
  });
}

export default useLogRenders;