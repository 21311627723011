import React, { useEffect, useState } from 'react';
import Header from '../Header/Header'; // Import Header
import Footer from '../Footer/Footer'; // Import Footer
import './TermsOfService.css'; // Import the CSS for this component

const TermsOfService = () => {
  const [content, setContent] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    fetch('/tos.html')
      .then(response => response.text())
      .then(html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const body = doc.body;
        setContent(body.innerHTML);
      })
      .catch(err => console.error('Failed to load terms of service:', err));
  }, []);

  const handleSession = (userInfo) => {
    console.log("handleSession", userInfo);
    if (userInfo) {
        setUser(userInfo);
    } else {
        setUser(null); // Clear the user session if null is passed
    }
  };

  return (
    <div className="tos-page"> {/* Apply the consistent background styling */}
      <Header handleSession={handleSession} user={user} /> {/* Include the Header */}
      <div className="tos-content" dangerouslySetInnerHTML={{ __html: content }} />
      <Footer /> {/* Include the Footer */}
    </div>
  );
};

export default TermsOfService;