import React, { useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';

const LoginTest = () => {

    useEffect(() => {
        console.log('Current origin:', window.location.origin);
        console.log('Current URL:', window.location.href);
    }, []);

    const handleGoogleLoginSuccess = (credentialResponse) => {
      console.log('Google Login Success:', credentialResponse);
    };
  
    const handleGoogleLoginFailure = (error) => {
      console.error('Google Login Failed:', error);
      console.error('Error details:', JSON.stringify(error, null, 2));
    };
  
    return (
      <div className="side-container bot-side">
        <div className="google-login" style={{margin:'2rem'}}>
          <GoogleLogin 
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginFailure}
            text='Sign in'
            useOneTap
          />
        </div>
      </div>
    );
  };

export default LoginTest;