import React from 'react';
import './UserAvatar.css';

const UserAvatar = () => (
    <svg viewBox="0 0 50 50" className="user-avatar">
      <path
        d="M10 10 C 10 40, 40 40, 40 10"
        fill="#219653"
      /> 
      <circle cx="25" cy="25" r="8" fill="#6fcf97" /> 
      <rect x="17" y="18" width="4" height="4" rx="1.5" ry="1.5" fill="white" />
      <rect x="29" y="18" width="4" height="4" rx="1.5" ry="1.5" fill="white" />
      <path d="M17 28 Q25 35 33 28" stroke="black" strokeWidth="2" fill="none" /> 
      <rect x="18" y="8" width="14" height="10" rx="2" ry="2" fill="black" /> {/* Hair */}
    </svg>
  );

export default UserAvatar;
