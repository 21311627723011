import { useState, useCallback, useRef, useEffect } from 'react';
import log from '../utils/logger';

const useAudioRecording = (isAudioActive, setError, state) => {
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);  // New state for mic status

  // Initialize refs
  const mediaRecorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const vadInstanceRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const bufferRecorderRef = useRef(null);
  const audioBufferRef = useRef([]);
  const isBufferingRef = useRef(false);
  const isSetupAudioRecordingComplete = useRef(false);
  const prevMicStateRef = useRef(true);
  const callStateRef = useRef(state);
  const isAudioActiveRef = useRef(false);
  const gainNodeRef = useRef(null);

  // Update refs when props change
	useEffect(() => {
    callStateRef.current = state;
	}, [state]);

  // eslint-disable-next-line no-unused-vars
  const generalConfig = {
    fftSize: 256,                // Size of the FFT for frequency analysis. Higher values give more detail but are computationally expensive.
    smoothingTimeConstant: 0.8,   // Controls how quickly the analyser reacts to volume changes. Higher values make it less responsive but smoother.
    minDecibels: -70,            // Minimum decibel value for the analysis. Sounds below this are ignored.
    maxDecibels: -30,             // Maximum decibel value for the analysis. Sounds above this are capped.
    silentFramesThreshold: 130,    // Number of consecutive silent frames before considering the audio as silent.
    minRecordingDuration: 1000,     // Minimum recording duration in milliseconds
    voiceActivityThreshold: 0.3,   // Add this new parameter
    debugMode: true              // When true, outputs debug information to the console.
  };

  // Simple config apprach
  // eslint-disable-next-line no-unused-vars
  const simpleThresholdConfig = {
    approach: 'simple-threshold',   // Identifies this as the simple threshold approach.
    thresholdLevel: 40,             // The volume level above which sound is considered speech. Range: 0-255.
  };

  // Moving Average Approach
  // eslint-disable-next-line no-unused-vars
  const movingAverageConfig = {
    approach: 'moving-average',     // Identifies this as the moving average approach.
    thresholdLevel: 30,             // Base level for considering a frequency as active. Lower values increase sensitivity.
    thresholdPercentage: 0.4,       // Percentage of active frequencies required to trigger voice detection.
    smoothingFactor: 0.6,           // Controls how quickly the moving average adapts. Higher values make it less responsive but more stable.
  };

  // Frequency Distribution Approach
  // eslint-disable-next-line no-unused-vars
  const frequencyDistributionConfig = {
    approach: 'frequency-distribution', // Identifies this as the frequency distribution approach.
    thresholdLevel: 130,                 // Amplitude threshold for considering a frequency as significant.
    thresholdPercentage: 0.15,          // Percentage of significant frequencies required to detect voice.
    cooldownPeriod: 1000                // cooldown period in ms
  };

  // Function to merge configs
  const mergeConfigs = (generalConfig, specificConfig) => {
    return { ...generalConfig, ...specificConfig };
  };

  const useVADConfig = mergeConfigs(generalConfig, frequencyDistributionConfig);

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Function to create and connect gain node
  const createGainNode = useCallback(() => {
    if (!audioContextRef.current) {
      log.warn('@createGainNode AudioContext not initialized');
      return;
    }
    gainNodeRef.current = audioContextRef.current.createGain();
    gainNodeRef.current.gain.setValueAtTime(1, audioContextRef.current.currentTime);
  }, []);

  // Function to adjust bot volume
  const adjustBotVolume = useCallback((isRecording) => {
    if (!gainNodeRef.current || !audioContextRef.current) {
      log.warn('@adjustBotVolume Gain node or Audio Context not initialized');
      return;
    }
    const volume = isRecording ? 0.2 : 1; // Reduce to 20% when recording, otherwise full volume
    gainNodeRef.current.gain.setValueAtTime(volume, audioContextRef.current.currentTime);
  }, []);

  // Start buffering function
  const startBuffering = useCallback(() => {
    if (isBufferingRef.current) return;
    isBufferingRef.current = true;

    const bufferRecorder = new MediaRecorder(mediaStreamRef.current);
    bufferRecorderRef.current = bufferRecorder;

    bufferRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioBufferRef.current.push(event.data);
        if (audioBufferRef.current.length > 150) { // Keep last 15 seconds (assuming 100ms chunks)
          audioBufferRef.current.shift();
        }
      }
    };

    bufferRecorder.start(100); // Record in 100ms chunks
  }, []);

  // eslint-disable-next-line no-unused-vars
  const implementCustomVAD = useCallback((stream, onVoiceStart, onVoiceStop, config = {}) => {
    const mergedConfig = { ...useVADConfig, ...config };
  
    const {
      approach,
      fftSize,
      smoothingTimeConstant,
      minDecibels,
      maxDecibels,
      thresholdPercentage,
      silentFramesThreshold,
      smoothingFactor,
      debugMode,
      cooldownPeriod,
      minRecordingDuration,
      voiceActivityThreshold
    } = mergedConfig;
  
    let thresholdLevel = mergedConfig.thresholdLevel;
    let audioContext, analyser, source, bufferLength, dataArray;
    let voiceDetected = false;
    let silentFrames = 0;
    let lastVoiceActivityTime = Date.now(); // Initialize to current time
    let recordingStartTime = 0;
    let animationFrameId = null;
    let cooldownTimeoutId = null;
  
    try {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      analyser = audioContext.createAnalyser();
      source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
  
      analyser.fftSize = fftSize;
      analyser.smoothingTimeConstant = smoothingTimeConstant;
      analyser.minDecibels = minDecibels;
      analyser.maxDecibels = maxDecibels;
  
      bufferLength = analyser.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);
    } catch (error) {
      console.error('Error setting up audio context:', error);
      return null;
    }
  
    const applyCooldown = () => {
      if (cooldownTimeoutId) clearTimeout(cooldownTimeoutId);
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
      cooldownTimeoutId = setTimeout(() => {
        animationFrameId = requestAnimationFrame(checkAudioLevel);
      }, cooldownPeriod);
    };
  
    let lastDebugUpdateTime = 0;
    const DEBUG_UPDATE_INTERVAL = 1000; // Update debug stats every 1 second

    const checkAudioLevel = () => {
      try {
        analyser.getByteFrequencyData(dataArray);
  
        let result;
        let currentValue;
        let percentageAboveThreshold;
        let significantFrequencies;
        let adjustedThreshold;
  
        switch (approach) {
          case 'simple-threshold':
            currentValue = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;
            result = simpleThresholdApproach(currentValue, thresholdLevel);
            percentageAboveThreshold = dataArray.reduce((count, value) => count + (value > thresholdLevel ? 1 : 0), 0) / bufferLength;
            break;
          case 'moving-average':
            currentValue = dataArray.reduce((count, value) => count + (value > thresholdLevel ? 1 : 0), 0) / bufferLength;
            result = movingAverageApproach(currentValue, thresholdPercentage, smoothingFactor);
            percentageAboveThreshold = currentValue;
            break;
          case 'frequency-distribution':
            ({ result, currentValue, adjustedThreshold, significantFrequencies, percentageAboveThreshold } = 
              frequencyDistributionApproach(dataArray, thresholdLevel, thresholdPercentage));
            thresholdLevel = adjustedThreshold;
            break;
          default:
            console.error('Unknown VAD approach');
            return;
        }
  
        const currentTime = Date.now();

        if (result) {
          // Voice activity detected
          if (!voiceDetected) {
            voiceDetected = true;
            recordingStartTime = currentTime;
            onVoiceStart();
          }
          silentFrames = 0;
          lastVoiceActivityTime = currentTime;
        } else {
          // No voice activity
          const silenceDuration = currentTime - lastVoiceActivityTime;
          const framesSinceLastVoice = Math.floor(silenceDuration / 16.67); // Assuming 60fps

          if (voiceDetected) {
            silentFrames = Math.min(silentFrames + 1, framesSinceLastVoice);

            const recordingDuration = currentTime - recordingStartTime;
            
            if (silentFrames >= silentFramesThreshold && 
                recordingDuration >= minRecordingDuration &&
                percentageAboveThreshold < voiceActivityThreshold) {
              voiceDetected = false;
              onVoiceStop();
              silentFrames = 0;
              applyCooldown();
              return;
            }
          }
        }
  
        if (debugMode) {
          if (currentTime - lastDebugUpdateTime > DEBUG_UPDATE_INTERVAL) {
            const stats = {
              approach,
              voiceDetected,
              silentFrames,
              currentValue,
              result,
              threshold: thresholdLevel,
              percentageAboveThreshold,
              lastVoiceActivityTime,
              currentTime,
              silenceDuration: currentTime - lastVoiceActivityTime,
              recordingDuration: voiceDetected ? currentTime - recordingStartTime : 0,
              ...(approach === 'frequency-distribution' && {
                significantFrequencies,
                totalFrequencies: bufferLength,
                thresholdPercentage,
                staticThreshold: mergedConfig.thresholdLevel,
                dynamicThreshold: adjustedThreshold
              })
            };
            setDebugStats(stats);
            lastDebugUpdateTime = currentTime;
          }
        }
  
        animationFrameId = requestAnimationFrame(checkAudioLevel);
      } catch (error) {
        console.error('Error in checkAudioLevel:', error);
      }
    };
  
    const simpleThresholdApproach = (average, threshold) => average > threshold;
  
    const movingAverageApproach = (currentValue, threshold, factor) => {
      let movingAverage = 0;
      movingAverage = factor * movingAverage + (1 - factor) * currentValue;
      return movingAverage > threshold;
    };
  
    const adjustThreshold = (currentValue, staticThreshold) => {
      return Math.max(
        staticThreshold * 0.5,
        Math.min(
          staticThreshold * 1.5,
          thresholdLevel * 0.95 + currentValue * 0.05
        )
      );
    };
  
    const frequencyDistributionApproach = (data, thresholdLevel, thresholdPercentage) => {
      const currentValue = data.reduce((sum, value) => sum + value, 0) / data.length;
      const adjustedThreshold = adjustThreshold(currentValue, thresholdLevel);
      const significantFrequencies = data.reduce((count, value) => count + (value > adjustedThreshold ? 1 : 0), 0);
      const percentageAboveThreshold = significantFrequencies / data.length;
      return {
        result: percentageAboveThreshold > thresholdPercentage,
        currentValue,
        adjustedThreshold,
        significantFrequencies,
        percentageAboveThreshold
      };
    };
  
    checkAudioLevel();
  
    return {
      stop: () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        if (cooldownTimeoutId) {
          clearTimeout(cooldownTimeoutId);
        }
        source.disconnect();
        if(audioContext) audioContext.close();
      },
      updateConfig: (newConfig) => {
        Object.assign(mergedConfig, newConfig);
        analyser.fftSize = mergedConfig.fftSize;
        analyser.smoothingTimeConstant = mergedConfig.smoothingTimeConstant;
        analyser.minDecibels = mergedConfig.minDecibels;
        analyser.maxDecibels = mergedConfig.maxDecibels;
      },
      getState: () => ({
        voiceDetected,
        silentFrames,
        approach: mergedConfig.approach
      }),
      reset: () => {
        voiceDetected = false;
        silentFrames = 0;
        thresholdLevel = mergedConfig.thresholdLevel;
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        if (cooldownTimeoutId) {
          clearTimeout(cooldownTimeoutId);
        }
        checkAudioLevel();
      }
    };
  }, [useVADConfig]);

  // Initialize VAD
  const initializeVAD = useCallback((stream) => {
    if (mediaStreamRef.current && !vadInstanceRef.current) {
      log.info("Initializing VAD...");
      try {
        vadInstanceRef.current = implementCustomVAD(
          mediaStreamRef.current,
          debounce(() => {
            if (!prevMicStateRef.current || !isAudioActiveRef.current) return;
            
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'inactive') {
              log.info('Voice detected, starting recording...');
              adjustBotVolume(true);
              setAudioChunks([]);
              mediaRecorderRef.current.start();
              setIsRecording(true);
              log.info('Recording started, state updated');
            }
          }, 100), // Reduced debounce time for quicker start
          debounce(() => {
            //if (!prevMicStateRef.current || !isAudioActiveRef.current) return;
            
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
              log.info('Voice stopped, stopping recording...');
              adjustBotVolume(false);
              mediaRecorderRef.current.stop();
              setIsRecording(false);
              log.info('Recording stopped, state updated');

              setTimeout(() => {
                setIsListening(true);
                initializeVAD(stream);
              }, frequencyDistributionConfig.cooldownPeriod);
            }
          }, 300), // Increased debounce time for stop to allow for longer pauses
          useVADConfig
        );
        log.debug('VAD initialized');
      } catch (error) {
        log.error('Error initializing VAD:', error);
        console.error('VAD initialization error details:', error);
      }
    }
  }, [implementCustomVAD, useVADConfig, frequencyDistributionConfig.cooldownPeriod, adjustBotVolume]);


  const initializeAudioContext = useCallback(async () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      await audioContextRef.current.resume();
      log.debug('AudioContext initialized and resumed:', audioContextRef.current);
    } else if (audioContextRef.current.state === 'suspended') {
      await audioContextRef.current.resume();
      log.debug('AudioContext resumed');
    }
  }, [audioContextRef]);

  // Utility function to initialize media recorder and audio context
  const setupAudioRecording = useCallback(async (micStateOn) => {

    if (callStateRef.current === 'idle') {
      //log.info('State is idle, not setting up audio recording');
      return false; // Return false to indicate setup was not performed
    }

    if (isSetupAudioRecordingComplete.current) {
      log.info('Audio recording already set up');
      return;
    }

    if (mediaRecorderRef.current) {
      log.info('MediaRecorder already set up');
      return;
    }

    try {
      log.info('Setting up audio recording');
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
        },
      });
      log.info('User media obtained with noise suppression');
      mediaStreamRef.current = stream;

      // Set initial state of audio tracks based on isMicOn
      stream.getAudioTracks().forEach(track => {
        track.enabled = micStateOn && isAudioActive ? true : false;
      });

      // Initialize or resume AudioContext
      await initializeAudioContext()

      createGainNode();

      // Initialize MediaRecorder
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setAudioChunks((prev) => [...prev, event.data]);
        }
      };

      recorder.onstop = () => {
        setIsRecording(false);
        // Reinitialize VAD after stopping
        initializeVAD();
      };

      mediaRecorderRef.current = recorder;

      // Initialize Analyser and MediaStreamSource
      if (audioContextRef.current && audioContextRef.current.state === 'running') {
        if (!analyserRef.current) {
          analyserRef.current = audioContextRef.current.createAnalyser();
          analyserRef.current.fftSize = 2048;
          log.debug('Analyser node created');
        }

        if (sourceRef.current) {
          sourceRef.current.disconnect();
        }

        sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
        sourceRef.current.connect(analyserRef.current);
        log.debug('MediaStreamSource connected to Analyser');
      } else {
        log.warn('Audio context not ready');
      }

      // Start buffering
      startBuffering();

      // Initialize VAD only if mic is on
      if (micStateOn) {
        initializeVAD(stream);
      }
      isSetupAudioRecordingComplete.current = true;
    } catch (error) {
      log.error('Error setting up audio recording:', error);
      log.error('Detailed error:', error);
      log.log('AudioContext:', audioContextRef.current);
      log.log('Stream:', mediaStreamRef.current);

      if (setError) setError(`Error setting up audio recording: ${error.message}`);
    }
    return true; // Return true to indicate successful setup
  }, [setError, initializeVAD, startBuffering, initializeAudioContext, isAudioActive, createGainNode]);

  const startListening = useCallback(async(micStateOn) => {
    if (isListening || !micStateOn || !isAudioActive) {
      log.info('Already listening or mic is off, not starting to listen');
      return;
    }
    
    try {
      if (!analyserRef.current || !vadInstanceRef.current) {
        log.info('Audio system not fully initialized, attempting to set up');
        const setupSuccess = await setupAudioRecording(true);
        if (!setupSuccess) {
          log.error('Failed to set up audio recording');
          return;
        }
      }

      if (!analyserRef.current || !vadInstanceRef.current) {
        log.error('Audio system still not fully initialized after reinitialization attempt');
        return;
      }
    
      setIsListening(true);
      adjustBotVolume(true);

      if (vadInstanceRef.current.reset) {
        vadInstanceRef.current.reset();
      }
    
      log.info('Started listening for voice activity');
    } catch (error) {
      log.error('Error in startListening:', error);
      setError(`Error starting to listen: ${error.message}`);
    }
  }, [analyserRef, vadInstanceRef, isListening, setupAudioRecording, isAudioActive, setError, adjustBotVolume]);

  const resetAudioSetup = useCallback(() => {
    // Reset setup completion flag
    isSetupAudioRecordingComplete.current = false;
  
    // Stop and nullify MediaRecorder
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
  
    // Stop all tracks in the media stream
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => {
        track.stop();
        track.enabled = false;
      });
      mediaStreamRef.current = null;
    }
  
    // Disconnect and nullify audio source
    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }
  
    // Nullify analyser
    analyserRef.current = null;
  
    // Clear audio chunks
    setAudioChunks([]);
  
    // Reset recording and listening states
    setIsRecording(false);
    setIsListening(false);
  
    // Reset VAD instance
    if (vadInstanceRef.current) {
      vadInstanceRef.current.stop();
      vadInstanceRef.current = null;
    }
  
    // Reset audio context
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close().then(() => {
        audioContextRef.current = null;
        log.debug('AudioContext closed and reset');
      });
    }
  
    // Reset buffer recorder
    if (bufferRecorderRef.current) {
      if (bufferRecorderRef.current.state === 'recording') {
        bufferRecorderRef.current.stop();
      }
      bufferRecorderRef.current = null;
    }
  
    // Clear audio buffer
    audioBufferRef.current = [];
  
    // Reset buffering flag
    isBufferingRef.current = false;
  
    // Reset other flags
    // isRecordingActiveRef.current = false;
    // hasReceivedAudio.current = false;
    // isMonitoringSilence.current = false;
  
    log.info('Audio recording setup has been fully reset');
  }, [setAudioChunks, setIsRecording, setIsListening]);

  // Stop and cleanup recording
  const stopAndCleanupRecording = useCallback(() => {
    log.info('stopAndCleanupRecording called');

    if (callStateRef.current === 'idle') {
      log.info('State is idle, not cleaning up audio recording');
      return;
    }

    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      //setIsRecording(false);
      log.info('Recording stopped, state updated');
    }

    isSetupAudioRecordingComplete.current = false;

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
      mediaStreamRef.current = null;
    }

    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }

    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close().then(() => {
        log.debug('AudioContext closed');
        audioContextRef.current = null;
      });
    }

    if (vadInstanceRef.current) {
      vadInstanceRef.current.stop();
      vadInstanceRef.current = null;
    }

    if (analyserRef.current) {
      analyserRef.current.disconnect();
      analyserRef.current = null;
    }
    mediaRecorderRef.current = null;
    setIsRecording(false);
    setIsListening(false);
    adjustBotVolume(false);
  }, [adjustBotVolume]);

  useEffect(() => {
    if (callStateRef.current === 'idle' || isMicOn === prevMicStateRef.current) {
      return; // No change in mic state, do nothing
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach(track => {
        track.enabled = isMicOn;
      });
      log.info(`Mic ${isMicOn ? 'enabled' : 'muted'}`);
    } else {
      log.warn('No media stream available to toggle mic state');
    }

    if (isMicOn && !isSetupAudioRecordingComplete.current) {
      // Only setup and start listening if not already set up
      setupAudioRecording(true).then(() => {
        startListening(true);
      });
    }

    prevMicStateRef.current = isMicOn;
  }, [isMicOn, setupAudioRecording, startListening]);

  // Log recording state changes
  useEffect(() => {
    log.info(`Recording state changed: ${isRecording ? 'active' : 'inactive'}`);
    if (gainNodeRef.current) {
      log.info('Current gain value:', gainNodeRef.current.gain.value);
    }
  }, [isRecording]);

  // Update refs when props change
	useEffect(() => {
    if (isAudioActive !== isAudioActiveRef.current) {
      //log.debug('isAudioActive: ', isAudioActive);
      isAudioActiveRef.current = isAudioActive;
      if(isAudioActive){
        try {
          setupAudioRecording(true).then((setupSuccess) => {
            if (setupSuccess) {
              startListening(true);
            } else {
              log.warn('Audio recording setup failed, not starting listening');
            }
          });
        } catch (error) {
          log.error('Error during audio recording setup:', error);
          setError(`Error setting up audio recording: ${error.message}`);
        }
      }
    }
	}, [isAudioActive, setupAudioRecording, startListening, setError]);

  const [debugStats, setDebugStats] = useState(null);
  const getGainNode = useCallback(() => gainNodeRef.current, []);

  return {
    setupAudioRecording,
    stopAndCleanupRecording,
    audioChunks,
    analyserRef,
    audioContextRef,
    mediaRecorderRef,
    mediaStreamRef,
    sourceRef,
    startListening,
    isListening,
    isRecording,
    setIsListening,
    setIsRecording,
    isSetupAudioRecordingComplete,
    resetAudioSetup,
    debugStats,
    debugMode: useVADConfig.debugMode,
    setIsMicOn,  // handle mic state
    isMicOn,  // New state exposed
    getGainNode
  };
};

export default useAudioRecording;