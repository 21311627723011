import React, { memo, useCallback, useMemo } from 'react';
import { X, Minus, Phone } from 'lucide-react';
import VoiceChatWidget from '../VoiceChat/VoiceChatWidget';
import './CallNowWidget.css';
import useLogRenders from '../../hooks/useLogRenders';

const MemoizedVoiceChatWidget = memo(VoiceChatWidget);

const CallNowWidget = memo(({ callNowAgent, setCallNowAgent, getAgentImage, user }) => {

    useLogRenders('CallNowWidget');
    const handleCallNowClose = useCallback(() => {
        if (window.location.hash) {
            //window.history.pushState("", document.title, window.location.pathname + window.location.search);
        }
        setCallNowAgent({});
        window.scrollTo(0, 0);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    }, [setCallNowAgent]);

    const handleCallNowMin = useCallback(() => {
        setCallNowAgent(prev => ({name: prev.name, state: 'min'}));
        window.scrollTo(0, 0);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    }, [setCallNowAgent]);

    const handleCallNowOpen = useCallback(() => {
        setCallNowAgent(prev => ({name: prev.name, state: 'open'}));
        window.scrollTo(0, 1); // Scroll a little to hide the URL bar
        document.documentElement.requestFullscreen();
    }, [setCallNowAgent]);

    const agentImageUrl = useMemo(() => getAgentImage(callNowAgent.name), [getAgentImage, callNowAgent.name]);

    const voiceChatProps = useMemo(() => ({
        agentName: callNowAgent.name,
        user: user
    }), [callNowAgent.name, user]);

    return (
        <>
            {callNowAgent.state === 'open' ? (
                <div className='call-now-widget'>
                    <img 
                        src={agentImageUrl} 
                        alt={`${callNowAgent.name} Support Agent`} 
                        className="card-image" 
                    />
                    <div className='gradient-background'></div>
                    <div className='transparent-div'></div>
                    <div className='call-widget-header'>
                        <div>RAPO VOICE CALL</div>
                        <div>
                            <Minus className='call-now-min' size={20} onClick={handleCallNowMin} />
                            <X className='call-now-close' size={20} onClick={handleCallNowClose} />
                        </div>
                    </div>
                    <MemoizedVoiceChatWidget key={callNowAgent.name} {...voiceChatProps}/>
                </div>
            ) : (
                <div 
                    className='call-now-button d-flex-c' 
                    onClick={handleCallNowOpen}
                    style={{backgroundImage: `url(${agentImageUrl})`}}
                >
                    <span className='call-now-button-in d-flex-c'>
                        <Phone size={18} />
                    </span>
                </div>
            )}
        </>
    );
});

export default CallNowWidget;