import React, { useEffect, useRef, useCallback, memo, useMemo, useState } from 'react';
import useAudioRecording from '../../hooks/useAudioRecording';
import useWebSocket from '../../hooks/useWebSocket';
import './VoiceChatWidget.css';
import '../MicControl/MicControl.css';
import BotSide from './BotSide';
import UserSide from './UserSide';
//import MicControls from '../MicControl/MicControls';
import InfoIcon from './InfoIcon';
import ChatContainer from './ChatContainer';
import { Play, Square, Plug, ChevronDown, ChevronUp, MessageSquare } from 'lucide-react';
import log from '../../utils/logger';
import { convertAudioToWav } from '../../utils/audioUtils';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { throttle } from 'lodash';
import CallTranscript from '../CallTranscript/CallTranscript';

const MIN_WAVEFORM_HEIGHT = 20;

const VoiceChatWidget = memo(({ agentName, user }) => {
  // 1. State declarations
  const [state, setState] = useState('idle');
  const [error, setError] = useState(null);
  const [isUserTalking, setIsUserTalking] = useState(false);
  const [isBotAudioPlaying, setIsBotAudioPlaying] = useState('no');
  //const [micSensitivity, setMicSensitivity] = useState(80);
  //const [showSlider, setShowSlider] = useState(false);
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [showDebugIcon, setShowDebugIcon] = useState(false);
  const [waveData, setWaveData] = useState([
    { x: 0, y: 0, width: 60, height: MIN_WAVEFORM_HEIGHT, rx: 35, ry: 35 },
    { x: 70, y: 0, width: 60, height: MIN_WAVEFORM_HEIGHT, rx: 35, ry: 35 },
    { x: 140, y: 0, width: 60, height: MIN_WAVEFORM_HEIGHT, rx: 35, ry: 35 },
  ]);
  //const [currentTxid, setCurrentTxid] = useState(null);
  const [audioProgress, setAudioProgress] = useState(0);
  const [currentBotMessage, setCurrentBotMessage] = useState('');
  const [isCallInterrupt, setIsCallInterrupt] = useState(() => {
    const storedValue = localStorage.getItem('isCallInterrupt');
    return storedValue === null ? true : JSON.parse(storedValue);
  });
  const [isVADStatsExpanded, setIsVADStatsExpanded] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [isAudioActive, setIsAudioActive] = useState(false);
  const [frozenWaveData, setFrozenWaveData] = useState(null);
  const [queueDebugInfo, setQueueDebugInfo] = useState({});
  const [currentPlayingSeq, setCurrentPlayingSeq] = useState(null);
  const [pausedPlayingSeq, setPausedPlayingSeq] = useState(null);
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(false);

  // 2. Ref declarations
  //const sliderRef = useRef(null);
  const isPlaying = useRef(false);
  const sourceRef = useRef(null);
  const handleRecordingRef = useRef(null);
  const playNextAudioRef = useRef(null);
  const handleAudioEndedRef = useRef(null);
  const currentAudioTextPairIndex = useRef(0);
  const audioTextPairsRef = useRef({});
  const handleInterruptRef = useRef(() => {});
  const isMonitoringSilence = useRef(false);
  const currentTxidRef = useRef(null);
  const currentUserMessageRef = useRef(null);

  // 3. Hooks

  const {
    setupAudioRecording,
    stopAndCleanupRecording,
    audioChunks,
    isRecording,
    analyserRef,
    audioContextRef,
    startListening,
    isListening,
    setIsListening,
    isSetupAudioRecordingComplete,
    debugStats,
    debugMode,
    setIsMicOn,
    isMicOn,
    getGainNode
  } = useAudioRecording(isAudioActive, setError, state);

  const handleWebSocketMessage = useCallback(
    async (message) => {
      if (!audioContextRef.current) {
        log.warn('Audio context not initialized');
        return;
      }

      try {
        if (typeof message === 'string' || message instanceof Object) {
          const parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
          log.debug("Received message type: ", parsedMessage.type);
          
          if (parsedMessage.type === 'transcription') {
            const { content, txid } = parsedMessage;
            setQueueDebugInfo(prevInfo => ({
              ...prevInfo,
              [txid]: {
                ...(prevInfo[txid] || {}),
                query: content,
                totalMessages: prevInfo[txid]?.totalMessages || 0,
                receivedMessages: prevInfo[txid]?.receivedMessages || 0,
                messages: prevInfo[txid]?.messages || []
              }
            }));
            currentUserMessageRef.current = content;
            setState('processing');
          } else if (parsedMessage.type === 'audio_chunk') {
            const { messageCount, totalMessages, audio, text, txid, sequenceNumber } = parsedMessage;
            
            setQueueDebugInfo(prevInfo => ({
              ...prevInfo,
              [txid]: {
                ...prevInfo[txid],
                totalMessages,
                receivedMessages: (prevInfo[txid]?.receivedMessages || 0) + 1,
                lastSequenceNumber: sequenceNumber,
                messages: [...(prevInfo[txid]?.messages || []), { sequenceNumber, text }]
              }
            }));
            let txChanged = false;
            if (messageCount === 1) {
              // Initialize the array and totalMessages for this txid if it doesn't exist
              if (!audioTextPairsRef.current[txid]) {
                audioTextPairsRef.current[txid] = {
                  totalMessages: totalMessages,
                  audioTextPairs: [],
                };
              }
              if(currentTxidRef.current && isCallInterrupt && currentTxidRef.current !== txid) {
                txChanged = true;
                currentAudioTextPairIndex.current = 0;
                log.debug('txChanged: ', 'Old txid: ', currentTxidRef.current, 'New txid: ', txid);
              }
            }

            // Ensure audioTextPairsRef.current[txid] is initialized
            if (!audioTextPairsRef.current[txid]) {
              audioTextPairsRef.current[txid] = {
                totalMessages: totalMessages,
                audioTextPairs: [],
              };
            }

            const audioData = atob(audio);
            const audioArrayBuffer = new ArrayBuffer(audioData.length);
            const view = new Uint8Array(audioArrayBuffer);
            for (let i = 0; i < audioData.length; i++) {
              view[i] = audioData.charCodeAt(i);
            }
            const audioBuffer = await audioContextRef.current.decodeAudioData(audioArrayBuffer);

            // Push the audio and text into the array for the specific txid
            audioTextPairsRef.current[txid].audioTextPairs.push({
              audio: audioBuffer,
              text,
              sequenceNumber
            });

            // Sort the audioTextPairs array by sequenceNumber
            audioTextPairsRef.current[txid].audioTextPairs.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

            log.debug(`Received audio chunk: txid=${txid}, seq=${sequenceNumber}, total=${totalMessages}`);

            // Start playing the audio if no other audio is currently playing and not paused
            if (sequenceNumber === 1 && (!isPlaying.current || (isCallInterrupt && isPlaying.current && txChanged))) {
              if(txChanged){
                await handleAudioEndedRef.current(currentTxidRef.current);
              }
              currentAudioTextPairIndex.current = 0;
              currentTxidRef.current = txid;
              playNextAudioRef.current(txid);
            }
          } else if (parsedMessage.type === 'audio_end') {
            if (audioTextPairsRef.current[parsedMessage.txid]?.audioTextPairs.length === 0) {
              //setCurrentBotMessage('');
            }
          } 
          else if (parsedMessage.type === 'flush') {
            if (isPlaying.current) {
              handleInterruptRef.current();
            }
          } 
          else if (parsedMessage.error) {
            setError(parsedMessage.error);
            setState('error');
          }
        }
      } catch (error) {
        log.error('Error processing WebSocket message:', error);
      }
    },
    [audioContextRef, setState, setError, isCallInterrupt],
  );

  const { wsStatus, wsKey, sendMessage, sendUserInfo, connectWebSocket } = useWebSocket(user, agentName, handleWebSocketMessage);

  // 4. Utility functions

  const clearQueue = useCallback((handleType='') => {
    
    currentAudioTextPairIndex.current = 0;
    setCurrentBotMessage(''); // No need to include setCurrentBotMessage in dependencies
    setAudioProgress(0);
    isPlaying.current = false;
    currentUserMessageRef.current = '';
    setCurrentPlayingSeq(null);
    
    if(handleType !== 'handleAudioEnded'){
      //setCurrentTxid(null);
      currentTxidRef.current = null;
      isMonitoringSilence.current = false;

      setIsBotAudioPlaying('no');
      audioTextPairsRef.current = {};
      if(handleType !== 'handleInterrupt'){
        setQueueDebugInfo({});
      }
      sendMessage({ type: 'stop' });
    }
    if(handleType !== 'handleEndTalking'){
      setState('listening');
    }
  }, [sendMessage]);

  const handleAudioEnded = useCallback((txid) => {
    log.debug('handleAudioEnded called with txid:', txid, "isBotAudioPlaying: ", isBotAudioPlaying);
    // Reset state and cleanup for the completed txid
    clearQueue('handleAudioEnded');
    delete audioTextPairsRef.current[txid];

    const remainingTxids = Object.keys(audioTextPairsRef.current);
    if (remainingTxids.length > 0 && !isCallInterrupt) {
      const nextTxid = remainingTxids[0];
      currentTxidRef.current = nextTxid;
      playNextAudioRef.current(nextTxid);
    } 
    else {
      currentTxidRef.current = null;
      if(isBotAudioPlaying !== 'pause') { 
        setIsBotAudioPlaying('no');
      }
    }
  }, [isCallInterrupt, isBotAudioPlaying, clearQueue]);

  const playNextAudio = useCallback(
    (txid) => {
      if (sourceRef.current) {
        sourceRef.current.stop();
        sourceRef.current = null;
      }

      const audioTextPairsData = audioTextPairsRef.current[txid];
      if (!audioTextPairsData) {
        log.debug('No audioTextPairsData found for txid:', txid);
        return;
      }

      const { audioTextPairs, totalMessages } = audioTextPairsData;

      log.debug(state, `playNextAudio called txid: ${txid}, index@: ${currentAudioTextPairIndex.current} of audioTextPairs: ${audioTextPairs?.length}`);
      if (state === 'idle' || !audioTextPairs || audioTextPairs.length === 0) {
        return;
      }

      const startPlayback = () => {
        isPlaying.current = true;
        setState('botSpeaking');
        setIsListening(true); // Ensure Listening remains active
        
        const { audio, text } = audioTextPairs[currentAudioTextPairIndex.current];

        if (!audio) {
          log.error('Audio buffer is not available');
          return;
        }

        const source = audioContextRef.current.createBufferSource();
        source.buffer = audio;

        if (analyserRef.current) {
          source.connect(analyserRef.current);
        }
        const gainNode = getGainNode();
        if (gainNode) {
          // Connect your audio source to this gain node, then to the destination
          source.connect(gainNode).connect(audioContextRef.current.destination);
        } else {
          console.warn('Gain node not available, audio will play at full volume');
          source.connect(audioContextRef.current.destination);
        }

        sourceRef.current = source;
        sourceRef.current.startTime = audioContextRef.current.currentTime;

        source.onended = () => {
          currentAudioTextPairIndex.current++;
          if (currentAudioTextPairIndex.current < audioTextPairs.length && currentTxidRef.current === txid) {
            playNextAudioRef.current(txid);
          } else if(currentTxidRef.current === txid){
            handleAudioEndedRef.current(txid);
          }
        };

        try {
          source.start(0);
          setCurrentBotMessage(text);
          setCurrentPlayingSeq({ txid, sequenceNumber: audioTextPairs[currentAudioTextPairIndex.current].sequenceNumber });
          log.debug(`Started playing audio: txid=${txid}, index=${currentAudioTextPairIndex.current}`);

          if (isBotAudioPlaying === 'pause') {
            audioContextRef.current.suspend();
          } else {
            setIsBotAudioPlaying('yes');
            audioContextRef.current.resume();
          }

          let lastPlayedTime = audioContextRef.current.currentTime;

          const updateAudioProgress = () => {
            if (sourceRef.current && audioContextRef.current) {
              const currentTime = audioContextRef.current.currentTime;
              const elapsedTime = currentTime - sourceRef.current.startTime;
              
              if (isBotAudioPlaying !== 'pause') {
                lastPlayedTime = currentTime;

                if (totalMessages > 0) {
                  const completedChunksProgress = (currentAudioTextPairIndex.current / totalMessages) * 100;
                  const chunkProgress = (elapsedTime / sourceRef.current.buffer.duration) * (100 / totalMessages);
                  const overallProgress = Math.min(completedChunksProgress + chunkProgress, 100);
                  setAudioProgress(overallProgress);
                } else {
                  log.error(
                    'Invalid totalMessages or buffer duration:',
                    totalMessages,
                    sourceRef.current.buffer.duration,
                  );
                  setAudioProgress(0);
                }
              } else {
                setAudioProgress(0);
                // When paused, update the start time to maintain the correct position
                sourceRef.current.startTime += (currentTime - lastPlayedTime);
                lastPlayedTime = currentTime;
              }

              if (elapsedTime < sourceRef.current.buffer.duration || isBotAudioPlaying === 'pause') {
                requestAnimationFrame(updateAudioProgress);
              }
            }
          };
          
          updateAudioProgress();
          
        } catch (error) {
          log.error('Error during audio playback:', error);
          handleAudioEndedRef.current(txid);
        }
      };

      if (currentAudioTextPairIndex.current < audioTextPairs.length && audioContextRef.current) {
        if (audioContextRef.current.state === 'suspended') {
          audioContextRef.current.resume().then(() => {
            startPlayback();
          });
        } else {
          startPlayback();
        }
      }
    },
    [state, setAudioProgress, audioContextRef, setIsListening, analyserRef, isBotAudioPlaying, setCurrentPlayingSeq, getGainNode],
  );

  const handleRecording = useCallback(async () => {
    log.debug('handleRecording called. Current state:', state);
    log.debug('isSetupAudioRecordingComplete:', isSetupAudioRecordingComplete.current);

    if(wsStatus !== "connected"){
      connectWebSocket();
    }

    if(!isMicOn){
      setIsMicOn(true);
    }

    if (
      isSetupAudioRecordingComplete.current ||
      state === 'botSpeaking' ||
      state === 'processing' ||
      state === 'listening'
    ) {
      log.debug('Setup already complete, bot is speaking, processing, or listening. Skipping handleRecording');
      return;
    }

    try {
      setIsListening(true);
      setState('listening');

      log.debug('About to call setupAudioRecording isMicOn: ', isMicOn);
      await setupAudioRecording(true);
      log.debug('setupAudioRecording completed');

      sendUserInfo();

      isMonitoringSilence.current = true;
      startListening(true);
    } catch (error) {
      console.error('Error starting recording:', error);
      setError('Error starting recording');
      setState('error');
      setIsListening(false);
    }
  }, [
    setupAudioRecording,
    setIsListening,
    setState,
    setError,
    sendUserInfo,
    startListening,
    state,
    isSetupAudioRecordingComplete,
    isMicOn,
    setIsMicOn,
    connectWebSocket,
    wsStatus
  ]);

  const handleEndTalking = useCallback(() => {
    setState('idle');
    setError(null);
    setIsUserTalking(false);
    
    isSetupAudioRecordingComplete.current = false;

    // Reset wave data
    setWaveData([
      { x: 0, y: 0, width: 60, height: MIN_WAVEFORM_HEIGHT, rx: 35, ry: 35 },
      { x: 70, y: 0, width: 60, height: MIN_WAVEFORM_HEIGHT, rx: 35, ry: 35 },
      { x: 140, y: 0, width: 60, height: MIN_WAVEFORM_HEIGHT, rx: 35, ry: 35 },
    ]);

    stopAndCleanupRecording();
    
    // Clear the queue only when stopping
    clearQueue('handleEndTalking');
  }, [
    stopAndCleanupRecording,
    setState,
    setError,
    setIsUserTalking,
    isSetupAudioRecordingComplete,
    clearQueue,
  ]);

  const handleBotPause = useCallback(() => {
    if (audioContextRef.current?.state === 'running') {
      audioContextRef.current
        .suspend()
        .then(() => {
          setIsBotAudioPlaying('pause');
          setFrozenWaveData(waveData);
          setPausedPlayingSeq(currentPlayingSeq);  // Store the paused sequence
          setCurrentPlayingSeq(null);  // Clear the current playing sequence
          log.debug('Audio playback paused');
        })
        .catch((error) => log.error('Error pausing audio playback:', error));
    }
  }, [setIsBotAudioPlaying, audioContextRef, waveData, currentPlayingSeq]);

  const handleBotPlay = useCallback(() => {
    if (audioContextRef.current?.state === 'suspended') {
      audioContextRef.current
        .resume()
        .then(() => {
          setIsBotAudioPlaying('yes');
          setFrozenWaveData(null);
          setCurrentPlayingSeq(pausedPlayingSeq);  // Restore the paused sequence
          setPausedPlayingSeq(null);  // Clear the paused sequence
        })
        .catch((error) => log.error('Error resuming audio playback:', error));
    }
  }, [setIsBotAudioPlaying, audioContextRef, pausedPlayingSeq]);

  const toggleMic = useCallback(() => {
    setIsMicOn(prevState => !prevState);
  }, [setIsMicOn]);

  const handleInterrupt = useCallback(() => {
    // Stop and disconnect the audio source if it exists
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }

    clearQueue('handleInterrupt');
    setPausedPlayingSeq(null);  // Clear the paused sequence

    log.debug('Recording interrupted and ready for the next response');
  }, [setPausedPlayingSeq, clearQueue]);  // Add setPausedPlayingSeq to the dependency array

  const renderControlButton = useMemo(() => {
    return () => {
      if (state === 'botSpeaking') {
        return (
          <button
            style={{
              backgroundColor: '#FF0000',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              border: '1px solid #FFF',
              borderRadius: '100%',
            }}
            title="End Call"
            onClick={handleEndTalking}
          >
            <Square size={12} />
          </button>
        );
      }

      const isIdle = state === 'idle';
      const buttonStyle = {
        backgroundColor: isIdle ? '#4CAF50' : '#FF0000',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        border: '1px solid #FFF',
        borderRadius: '100%',
      };
      const buttonText = isIdle ? 'Start Call' : 'End Call';
      const buttonIcon = isIdle ? <Play size={12} /> : <Square size={12} />;

      return (
        <button style={buttonStyle} title={buttonText} onClick={isIdle ? handleRecording : handleEndTalking}>
          {buttonIcon}
        </button>
      );
    };
  }, [state, handleEndTalking, handleRecording]);

  const throttledSetWaveData = useMemo(
    () => throttle((newData) => setWaveData(newData), 50),
    []
  );

  const monitorAudio = useCallback(() => {
    if (!analyserRef.current || !audioContextRef.current) {
      log.warn('Analyser not initialized');
      return;
    }

    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteFrequencyData(dataArray);

    const average = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
    const normalizedAmplitude = Math.min(average / 128, 1);

    const MIN_WAVEFORM_HEIGHT = 20;
    const MAX_WAVEFORM_HEIGHT = 100;
    const sensitivityFactor = 3;

    const calculateHeight = () => {
      return MIN_WAVEFORM_HEIGHT + normalizedAmplitude * (MAX_WAVEFORM_HEIGHT - MIN_WAVEFORM_HEIGHT) * sensitivityFactor;
    };

    const newWaveData = [0, 1, 2].map((i) => ({
      x: i * 70,
      y: 0,
      width: 60,
      height: calculateHeight(),
      rx: 35,
      ry: 35,
    }));

    throttledSetWaveData(newWaveData);
    if (isBotAudioPlaying !== 'pause') {
      setFrozenWaveData(newWaveData);
    }
  }, [analyserRef, audioContextRef, throttledSetWaveData, isBotAudioPlaying]);

  useEffect(() => {
    let animationFrameId;

    const animate = () => {
      if (isRecording || isBotAudioPlaying === 'yes') {
        monitorAudio();
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isRecording, isBotAudioPlaying, monitorAudio]);

  // 6. useEffect hooks
  // Log all potentially unused variables
  useEffect(() => {
    log.debug('Component mounted');
    return () => {
      log.debug('Component unmounted');
    };
  }, []);

  useEffect(() => {}, [error]);

  // Keep refs updated with the latest functions
  useEffect(() => {
    handleRecordingRef.current = handleRecording;
    playNextAudioRef.current = playNextAudio;
    handleAudioEndedRef.current = handleAudioEnded;
    handleInterruptRef.current = handleInterrupt;
  }, [handleRecording, playNextAudio, handleAudioEnded, handleInterrupt]);

  useEffect(() => {
    return () => {
      handleEndTalking();
      if (audioContextRef.current) {
        if (audioContextRef.current.state !== 'closed') {
          audioContextRef.current.close().then(() => {
            log.debug('AudioContext closed');
          }).catch(error => {
            log.warn('Error closing AudioContext:', error);
          });
        } else {
          log.debug('AudioContext already closed');
        }
        audioContextRef.current = null;
        analyserRef.current = null;
      }
    };
  }, [handleEndTalking, audioContextRef, analyserRef]);

  //**** Responsible for Sending audio to the server when recording stops ****//
  useEffect(() => {
    if (!isRecording && audioContextRef.current ) {
      log.debug('@sendAudio', audioChunks.length);
      if (audioChunks.length > 0) {
        convertAudioToWav(audioChunks)
          .then((convertedBlob) => {
            log.debug('Audio converted to WAV, size:', convertedBlob.size);
            // if(isCallInterrupt){
            //   handleAudioEndedRef.current(currentTxidRef.current);
            // }
            sendMessage(convertedBlob);
          })
          .catch((error) => {
            log.error('Error converting audio to WAV:', error);
          });
      } else {
        log.warn('No audio chunks to send');
      }
    }
  }, [audioChunks, isRecording, audioContextRef, sendMessage]);

  //**** Responsible for continious audio recording ****//
  useEffect(() => {
    // log.debug('useEffect for handleRecording triggered. isRecording:', isRecording, 'state:', state);
    if (
      !isRecording &&
      state !== 'idle' &&
      state !== 'botSpeaking' &&
      state !== 'processing' &&
      state !== 'listening'
    ) {
      log.debug('Conditions met, calling handleRecording');
      handleRecording();
    }
  }, [isRecording, handleRecording, state]);

  // Ensure AudioContext and AnalyserNode are properly managed
  useEffect(() => {
    const audioContext = audioContextRef.current;
    return () => {
      if (audioContext) {
        log.debug('Closing AudioContext');
        audioContext.close();
      }
    };
  }, [audioContextRef]);

  useEffect(() => {
    if (audioContextRef.current && analyserRef.current) {
      log.debug('AudioContext and AnalyserNode are available');
    } else {
      log.warn('AudioContext or AnalyserNode not available');
    }
  }, [audioContextRef, analyserRef]);

  useEffect(() => {
    if (wsStatus === "connected") {
      setIsAudioActive(true);
    } else {
      setIsAudioActive(false);
    }
  }, [wsStatus]);

  const formatDebugStats = useCallback((stats) => {
    if (!stats) return null;

    const formatTime = (timestamp) => format(new Date(timestamp), 'HH:mm:ss');
    const formatDurationFromMs = (ms) => {
      const duration = intervalToDuration({ start: 0, end: ms });
      return formatDuration(duration, { format: ['hours', 'minutes', 'seconds'], zero: true });
    };

    return {
      ...stats,
      currentTime: formatTime(stats.currentTime),
      lastVoiceActivityTime: stats.lastVoiceActivityTime ? formatTime(stats.lastVoiceActivityTime) : 'N/A',
      silenceDuration: formatDurationFromMs(stats.silenceDuration),
      recordingDuration: formatDurationFromMs(stats.recordingDuration),
      threshold: Number(stats.threshold).toFixed(2),
      percentageAboveThreshold: (stats.percentageAboveThreshold * 100).toFixed(2) + '%',
      dynamicThreshold: Number(stats.dynamicThreshold).toFixed(2),
    };
  }, []);

  const UPDATE_INTERVAL = 1000; // Update every 1 second

  const memoizedDebugStats = useMemo(() => {
    const currentTime = Date.now();
    if (currentTime - lastUpdateTime > UPDATE_INTERVAL) {
      setLastUpdateTime(currentTime);
      return formatDebugStats(debugStats);
    }
    return formatDebugStats(debugStats); // Always return formatted stats
  }, [debugStats, formatDebugStats, lastUpdateTime, UPDATE_INTERVAL]);

  const MemoizedBotSide = useMemo(
    () => (
      <BotSide
        state={state}
        waveData={isBotAudioPlaying === 'pause' ? frozenWaveData : waveData}
        handleInterrupt={handleInterrupt}
        handleBotPause={handleBotPause}
        handleBotPlay={handleBotPlay}
        currentBotMessage={currentBotMessage}
        audioProgress={audioProgress}
        isBotAudioPlaying={isBotAudioPlaying}
        isListening={isListening}
        setIsCallInterrupt={setIsCallInterrupt}
        isCallInterrupt={isCallInterrupt}
        currentTxid={currentTxidRef.current}
        isPaused={isBotAudioPlaying === 'pause'}
      />
    ),
    [
      state,
      isListening,
      waveData,
      frozenWaveData,
      handleInterrupt,
      handleBotPause,
      handleBotPlay,
      currentBotMessage,
      audioProgress,
      isBotAudioPlaying,
      isCallInterrupt
    ],
  );

  const MemoizedUserSide = useMemo(
    () => (
      <UserSide
        isUserTalking={isUserTalking}
        waveData={waveData}
        state={state}
        user={user}
        handleRecording={handleRecording}
        handleEndTalking={handleEndTalking}
        renderControlButton={renderControlButton}
        isRecording={isRecording}
        handleUserMic={toggleMic}
        isMicOn={isMicOn}
        currentUserMessage={currentUserMessageRef.current}
      />
    ),
    [
      isUserTalking, 
      isRecording, 
      waveData, 
      state, 
      user, 
      handleRecording, 
      handleEndTalking, 
      renderControlButton,
      toggleMic,
      isMicOn,
      currentUserMessageRef
    ],
  );

  const resumeAudioContext = useCallback(async () => {
    if (audioContextRef.current && audioContextRef.current.state === 'suspended' && isBotAudioPlaying !== 'pause') {
      log.info('Resuming AudioContext...');
      try {
        await audioContextRef.current.resume();
        log.debug('AudioContext resumed');
      } catch (error) {
        log.error('Error resuming AudioContext:', error);
      }
    }
  }, [audioContextRef, isBotAudioPlaying]);

  const toggleVisibility = (type) => {
    if(type === 'transcript'){
      setIsTranscriptVisible(prev => {
        const newValue = !prev;
        if (newValue) {
          console.log('Transcript is now visible');
          setShowDebugInfo(false);
        }
        return newValue;
      });
    }
    else if(type === 'debug'){
      setShowDebugInfo(prev => {
        const newValue = !prev;
        if (newValue) {
          setIsTranscriptVisible(false);
        }
        return newValue;
      });
    }
    //setShowDebugInfo(prev => !prev)
  };

  useEffect(() => {
    if (isRecording || isBotAudioPlaying === 'yes') {
      resumeAudioContext();
    }
  }, [isRecording, isBotAudioPlaying, resumeAudioContext]);

  // New useEffect to handle debug mode
  useEffect(() => {
    const debugMode = localStorage.getItem('debug') === 'true';
    setShowDebugIcon(debugMode);
  }, []);

  return (
    <div className={`voice-chat-widget ${isTranscriptVisible || showDebugInfo ? 'info-on' : ''}`}>
      <ChatContainer>
        {MemoizedBotSide}
        {MemoizedUserSide}
      </ChatContainer>

      {showDebugInfo && (
        <div className="debug-info visible" style={{ maxHeight: '240px', overflowY: 'auto'}}>
          <p>Current state: {state}</p>
          <p>Is user talking: {isUserTalking ? 'Yes' : 'No'}</p>
          <p>Audio context state: {audioContextRef.current ? audioContextRef.current.state : 'Not initialized'}</p>
          <p>Analyser node: {analyserRef.current ? 'Created' : 'Not created'}</p>
          <p>Is monitoring silence: {isMonitoringSilence.current ? 'Yes' : 'No'}</p>
          <p>Listening: {isListening ? 'active' : 'inactive'}</p>
          <p>Recording: {isRecording ? 'active' : 'inactive'}</p>
          <p>isBotAudioPlaying: {isBotAudioPlaying}</p>
          <p>WebSocket status: {wsStatus}</p>
          <p>User email: {user?.email}</p>
          <p>WebSocket Key: {wsKey}</p>
          <p>Session Key: {user?.sessionKey}</p>
          <p>WebSocket status: {wsStatus}</p>
          {debugMode && debugStats && (
            <div className="vad-debug-stats" style={{ marginBottom: '10px' }}>
              <div className="vad-debug-header" onClick={() => setIsVADStatsExpanded(!isVADStatsExpanded)}>
                <span>Voice Activity Detection (VAD) Stats</span>
                <span>{isVADStatsExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}</span>
              </div>
              {isVADStatsExpanded && memoizedDebugStats && (
                <pre className="vad-debug-content">{JSON.stringify(memoizedDebugStats, null, 2)}</pre>
              )}
            </div>
          )}
        </div>
      )}
      {isTranscriptVisible && 
        <CallTranscript 
          queueDebugInfo={queueDebugInfo}
          currentPlayingSeq={currentPlayingSeq}
          pausedPlayingSeq={pausedPlayingSeq}
          isVisible={isTranscriptVisible}
          setIsVisible={setIsTranscriptVisible}
          toggleVisibility={toggleVisibility}
          debug={showDebugIcon}
        />
      }
      <div className="debug-controls-container">
        <div className="debug-icons">
          {showDebugIcon && <InfoIcon showDebugInfo={showDebugInfo} toggleVisibility={toggleVisibility} />}

          <div className={`transcript-icon ${isTranscriptVisible ? 'icon-on' : ''}`} onClick={() => toggleVisibility('transcript')}>
            <MessageSquare size={18} />
          </div>
        </div>
        <div className="ws-status-icon-container d-flex-c">
          <span className='d-flex-c' style={{fontSize: '0.75rem'}}>{wsStatus === "connected" ? 'Connected' : 'Disconnected'}: </span>
          <span 
            className="ws-status-icon d-flex-c"
            title={wsStatus === "connected" ? 'Connected to Server' : 'Disconnected from Server'}
          >
            <Plug size={16} color={wsStatus === "connected" ? "green" : "red"} />
          </span>
        </div>
      </div>
    </div>
  );
});

export default VoiceChatWidget;
