import React from 'react';
import { Info } from 'lucide-react';

const InfoIcon = ({ showDebugInfo, toggleVisibility }) => (
    <div className={`info-icon ${showDebugInfo ? 'icon-on' : ''}`} 
        onClick={() => toggleVisibility('debug')}>
        <Info size={18} />
    </div>
);
 
export default InfoIcon;

