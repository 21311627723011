import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const AutoInterrupt = ({ isCallInterrupt, setIsCallInterrupt }) => {

  const handleAutoCallInterrupt = (newValue) => {
    //const newValue = event.target.checked;
    setIsCallInterrupt(newValue);
    localStorage.setItem('isCallInterrupt', JSON.stringify(newValue));
    console.debug('isCallInterrupt: ', newValue);
  };

  return (
    <div className="switch-container d-flex">
      <label className='d-flex-c'>
        <span className="mr-2">AUTO INTERRUPT ME:</span>
        <Switch 
          checked={isCallInterrupt}
          onChange={handleAutoCallInterrupt}
          onColor="#eec8a6"
          onHandleColor="#f2994b"
          handleDiameter={16}  // Decreased from 24
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.4)"  // Slightly reduced shadow
          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"  // Adjusted active shadow
          height={12}  // Decreased from 20
          width={30}  // Decreased from 48
          className={`react-switch ${isCallInterrupt ? 'on' : 'off'}`}
        />
      </label>
    </div>
  );
};

AutoInterrupt.propTypes = {
  isCallInterrupt: PropTypes.bool.isRequired,
  setIsCallInterrupt: PropTypes.func.isRequired,
};

export default AutoInterrupt;