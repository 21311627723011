import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { initializeWebSocket } from '../utils/websocket';
import { debounce } from '../utils/debounce';
import log from '../utils/logger';

const MAX_RETRY_COUNT = 5;
const RECONNECTION_DELAY = 5000; // 5 seconds
const COOLDOWN_PERIOD = 60000; // 1 minute

const useWebSocket = (user, agentName, handleWebSocketMessage) => {
    const [wsStatus, setWsStatus] = useState('disconnected');
    const [wsReady, setWsReady] = useState(false);
    const [wsKey, setWsKey] = useState(null);
    const websocket = useRef(null);
    const isWsInitialized = useRef(false);
    const isUnmounted = useRef(false);
    const userRef = useRef(user);
    const agentNameRef = useRef(agentName);
    const handleWebSocketMessageRef = useRef(handleWebSocketMessage);
    const retryCountRef = useRef(0);
    const sendUserInfoRef = useRef(null);
    const debouncedConnectWebSocketRef = useRef(null);

    // Update refs when props change
    useEffect(() => {
        userRef.current = user;
        agentNameRef.current = agentName;
        handleWebSocketMessageRef.current = handleWebSocketMessage;
    }, [user, agentName, handleWebSocketMessage]);

    const handleWebSocketError = useCallback((event) => {
        log.error('WebSocket connection error:', event);
        setWsStatus('disconnected');
        setWsReady(false);
    }, []);

    const handleWebSocketClose = useCallback((event) => {
        setWsStatus('disconnected');
        setWsReady(false);
        isWsInitialized.current = false;
        if (websocket.current) {
            websocket.current.close();
            websocket.current = null;
        }
    }, []);

    const closeExistingConnection = useCallback(() => {
        if (websocket.current) {
            log.debug('Closing existing WebSocket connection');
            websocket.current.close();
            websocket.current = null;
        }
        clearTimeout(window.wsReconnectTimeout);
    }, []);

    const scheduleReconnection = useCallback(() => {
        if (retryCountRef.current < MAX_RETRY_COUNT) {
            log.debug(`Scheduling WebSocket reconnection attempt ${retryCountRef.current + 1}/${MAX_RETRY_COUNT}`);
            clearTimeout(window.wsReconnectTimeout);
            window.wsReconnectTimeout = setTimeout(() => {
                if (isUnmounted.current) {
                    log.debug('Component unmounted, skipping reconnection');
                    return;
                }
                log.debug(`Attempting to reconnect WebSocket... (${retryCountRef.current + 1}/${MAX_RETRY_COUNT})`);
                retryCountRef.current += 1;
                if (debouncedConnectWebSocketRef.current) {
                    debouncedConnectWebSocketRef.current();
                }
            }, RECONNECTION_DELAY);
        } else {
            log.debug('Max reconnection attempts reached. Resetting retry count.');
            retryCountRef.current = 0; // Reset the retry count
            // Optionally, you can add a longer delay before allowing reconnection attempts
            setTimeout(() => {
                log.debug('Reconnection attempts allowed again after cooldown period.');
            }, COOLDOWN_PERIOD);
        }
    }, []);

    const connectWebSocket = useCallback(() => {
        log.debug('connectWebSocket called');
        closeExistingConnection();
        try {
            log.debug('Debounced connectWebSocket called');
            if (websocket.current) {
                log.debug('Closing existing WebSocket connection');
                websocket.current.close();
                websocket.current = null;
            }

            setWsStatus('connecting');
            log.debug('Attempting to connect WebSocket...');

            websocket.current = initializeWebSocket({
                onMessage: (message) => {
                    log.debug('WebSocket message received');
                    handleWebSocketMessageRef.current(message);
                },
                onError: (error) => {
                    log.error('WebSocket error in hook:', error);
                    handleWebSocketError(error);
                },
                onOpen: () => {
                    log.debug('WebSocket connection established in hook');
                    retryCountRef.current = 0; // Reset retry count on successful connection
                    setWsKey(`ws_${Date.now()}`);
                    setWsStatus('connected');
                    setWsReady(true);
                    isWsInitialized.current = true;
                    
                    // Send user info after successful connection
                    if (sendUserInfoRef.current) {
                        sendUserInfoRef.current();
                    }
                },
                onClose: (event) => {
                    if (event === undefined) {
                        setTimeout(() => {
                            if (!isUnmounted.current) {
                                log.debug('Attempting to reconnect after error');
                                connectWebSocket();
                            }
                        }, 5000); // 5 second delay before reconnecting
                    } else {
                        log.debug('WebSocket closed in hook:', event);
                        handleWebSocketClose(event);
                        setWsReady(false);

                        if (event.code !== 1000) {
                            scheduleReconnection();
                        }
                    }
                },
                isUnmounted,
            });

            log.debug('WebSocket initialization completed');

            // Add a check to ensure the WebSocket was properly initialized
            if (!websocket.current) {
                throw new Error('WebSocket failed to initialize');
            }
        } catch (error) {
            log.error('Error in connectWebSocket:', error);
            setWsStatus('error');
            setWsReady(false);
            // Attempt to reconnect after a delay
            setTimeout(() => {
                if (!isUnmounted.current) {
                    log.debug('Attempting to reconnect after error');
                    connectWebSocket();
                }
            }, 5000); // 5 second delay before reconnecting
        }
    }, [handleWebSocketError, handleWebSocketClose, closeExistingConnection, scheduleReconnection]);

    debouncedConnectWebSocketRef.current = useMemo(
        () => debounce(connectWebSocket, 1000),
        [connectWebSocket]
    );

    useEffect(() => {
        log.debug('useEffect in useWebSocket hook triggered');
        isUnmounted.current = false;
        log.debug('isUnmounted set to false');

        if (debouncedConnectWebSocketRef.current) {
            debouncedConnectWebSocketRef.current();
        }

        return () => {
            log.debug('Cleanup function in useWebSocket hook triggered');
            if (debouncedConnectWebSocketRef.current) {
                debouncedConnectWebSocketRef.current.cancel();
            }
            closeExistingConnection();
            isUnmounted.current = true;
            log.debug('isUnmounted set to true');
        };
    }, [closeExistingConnection]);

    const sendMessage = useCallback((message) => {
        if (isUnmounted.current) {
            log.warn('Attempted to send message after component unmounted:', message);
            return;
        }

        log.debug('sendMessage called', websocket.current);
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            if (message instanceof Blob) {
                websocket.current.send(message);
            } else {
                websocket.current.send(JSON.stringify(message));
            }
            log.debug('Message sent successfully');
        } else {
            log.error('WebSocket is not open. Message not sent:', message);
            if (!websocket.current || websocket.current.readyState === WebSocket.CLOSED) {
                log.debug('WebSocket is closed. Attempting to reconnect.');
                scheduleReconnection();
            } else if (websocket.current.readyState === WebSocket.CONNECTING) {
                log.debug('WebSocket is still connecting. Waiting for connection.');
            } else if (websocket.current.readyState === WebSocket.CLOSING) {
                log.debug('WebSocket is closing. Will attempt to reconnect once closed.');
            }
        }
    }, [scheduleReconnection]);

    sendUserInfoRef.current = useCallback(() => {
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN && userRef.current) {
            const userInfo = {
                type: 'user_info',
                wsKey: wsKey || `ws_${Date.now()}`,
                sessionKey: userRef.current.sessionKey,
                email: userRef.current.email,
                userId: userRef.current.id,
                conversationType: agentNameRef.current,
            };
            sendMessage(userInfo);
            setWsKey(userInfo.wsKey);
        }
    }, [wsKey, sendMessage]);

    return {
        wsStatus,
        wsReady,
        wsKey,
        sendMessage,
        sendUserInfo: sendUserInfoRef.current,
        isReady: isWsInitialized.current,
		connectWebSocket
    };
};

export default useWebSocket;