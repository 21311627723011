// src/utils/logger.js
import log from 'loglevel';

const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const logLevelNames = {
  TRACE: 0,
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  SILENT: 5,
};

const logLevelNumbers = Object.keys(logLevelNames).reduce((acc, key) => {
  acc[logLevelNames[key]] = key.toLowerCase();
  return acc;
}, {});

// Get initial log level from query parameters or localStorage or default
const queryLogLevel = getQueryParam('logLevel');
const savedLogLevel = localStorage.getItem('logLevel');
const defaultLogLevel = process.env.NODE_ENV === 'development' ? 'debug' : 'error';

let initialLogLevel;
if (queryLogLevel) {
  initialLogLevel = queryLogLevel;
  localStorage.setItem('logLevel', queryLogLevel); // Save to localStorage
} else if (savedLogLevel) {
  initialLogLevel = savedLogLevel;
} else {
  initialLogLevel = defaultLogLevel;
}

const initialLogLevelName = logLevelNumbers[initialLogLevel] || initialLogLevel;

if (logLevelNames[initialLogLevelName.toUpperCase()] !== undefined) {
  log.setLevel(initialLogLevelName);
} else {
  log.setLevel(defaultLogLevel);
}

export const setLogLevel = (level) => {
  let logLevelName;
  if (typeof level === 'string') {
    logLevelName = level.toLowerCase();
  } else if (typeof level === 'number') {
    logLevelName = logLevelNumbers[level];
  }

  if (logLevelName && logLevelNames[logLevelName.toUpperCase()] !== undefined) {
    log.setLevel(logLevelName);
    localStorage.setItem('logLevel', logLevelName);
    return true;
  } else {
    console.error('Invalid log level:', level);
    return false;
  }
};

export const getLogLevel = () => {
  const level = log.getLevel();
  const levelName = Object.keys(log.levels).find(key => log.levels[key] === level).toUpperCase();
  return `${level} (${levelName})`;
};

export const showLogLevels = () => {
  return logLevelNames;
};

export default log;
