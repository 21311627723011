import React, { memo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from '../Home/Home';
//import VoiceChatWidget from '../VoiceChat/VoiceChatWidget';
import CallPage from '../CallPage/CallPage';
import LoginTest from '../VoiceChat/LoginTest';
import Privacy from '../Privacy/Privacy';
import TermsOfService from '../TermsOfService/TermsOfService';
import AudioContextTest from '../AudioContextTest'; // Import the AudioContextTest component
import { setLogLevel } from '../../utils/logger'; // Import the setLogLevel function
import './App.css'; // Import your global CSS file (if needed)
import ContactUs from '../ContactUs/ContactUs';

// Check for log level in localStorage
const savedLogLevel = localStorage.getItem('logLevel');

if (!savedLogLevel) {
  // Set log level based on the environment if not found in localStorage
  if (process.env.NODE_ENV === 'production') {
    setLogLevel('error'); // Only show error logs in production
  } else {
    setLogLevel('debug'); // Show debug logs during development
  }
} else {
  // Set log level from localStorage
  setLogLevel(savedLogLevel);
}

const AppContent = memo(function AppContent() {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const debugParam = searchParams.get('debug');

    if (debugParam === 'true') {
      localStorage.setItem('debug', 'true');
    } else if (debugParam === 'false') {
      localStorage.removeItem('debug');
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/call" element={<CallPage />} />
      <Route path="/test-login" element={<LoginTest />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/tos" element={<TermsOfService />} />
      <Route path="/audio-test" element={<AudioContextTest />} /> {/* Add this line */}
      <Route path="/contactus" element={<ContactUs />} />
    </Routes>
  );
});

const App = memo(function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
});

export default App;